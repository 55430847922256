html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Source Sans Pro', sans-serif;
}
.orderDetailStyle {
  border-radius: 15px !important;
  margin-left: 5px;
  margin-right: 5px;
}

.button-scan .dx-button-content .dx-icon {
  font-size: 100px;
}

.event-detail-style {
}

.car-overView-style {
}

.qr-code-style {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
  text-align: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

::ng-deep .returnButtonStyle {
  .dx-icon {
    color: #000000 !important;
  }
}
